<template>
  <div class="has-background-light">
    <b-loading v-model="loading" is-full-page />
    <div
      class="container is-flex is-align-items-center is-justify-content-center"
      style="height: 100vh"
    >
      <section
        class="panel is-primary has-background-white"
        style="width: 320px"
      >
        <p class="panel-heading has-text-centered">
          <img
            src="/images/brand.png?v=20220414"
            width="240px"
            height="240px"
          />
          <span class="has-text-warning is-size-2">Atago Portal</span>
        </p>
        <div class="panel-block">
          <form>
            <div class="control mt-4">
              <b-field label="メールアドレス">
                <b-input icon="email" v-model="form.email"></b-input>
                <p class="control">
                  <span class="button is-static">@atago.cc</span>
                </p>
              </b-field>
              <b-field label="パスワード">
                <b-input
                  icon="form-textbox-password"
                  type="password"
                  v-model="form.password"
                  autocomplete="current-password"
                ></b-input>
              </b-field>
              <b-field>
                <b-checkbox v-model="form.rememberMe"
                  >ログイン情報を保存</b-checkbox
                >
              </b-field>
            </div>
          </form>
        </div>
        <div class="panel-block has-text-centered">
          <b-button
            type="is-primary"
            icon-left="login"
            expanded
            @click="login"
            :loading="storing"
            >ログイン</b-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      storing: false,
      form: {
        email: null,
        password: null,
        rememberMe: false,
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    async login() {
      try {
        this.storing = true;
        await this.$store.dispatch("auth/login", this.form);
        this.storing = false;
        this.$router.push({ name: "menu" });
      } catch (e) {
        this.storing = false;
        const message =
          e.status && [401, 422].includes(e.status)
            ? "メールアドレスまたはパスワードが違います。"
            : "現在、サービスが利用できません。この状態が長く続く場合はシステム管理者に連絡してください。";
        this.$buefy.toast.open({
          duration: 5000,
          message,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
  },
};
</script>
